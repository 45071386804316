<template>
  <div class="h-100 mx-0 d-flex">
    <div
      v-if="isValid"
      class="p-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center position-relative"
    >
      <img
        class="mx-auto logo"
        :srcset="require('@/assets/img/logo-orange-base.svg?srcset')"
      />
      <img
        class="d-block banner mt-md-2"
        :srcset="require('@/assets/img/modal/ok.png?srcset')"
      />
      <div class="app-title text-center mt-2">
        <div class="title text-28 font-weight-bold mb-2">
          {{ $t('綁定成功') }}
        </div>
        <div class="text-20 content">
          {{ $t('你已完成了信箱綁定！') }}<br />
          {{ $t('可以繼續學習圍棋了') }}<br />
        </div>
        <b-button
          variant="secondary"
          class="mx-auto mt-2"
          size="md"
          @click="goHome"
          >{{ $t('返回首頁') }}</b-button
        >
      </div>
    </div>
    <div v-else>
      <modal-confirm
        v-model="isRedirectModalShow"
        :confirm-text="$t('知道了')"
        :hide-cancel="true"
        @confirm="goHome"
      >
        {{ $t('此驗證信時效已到期，將幫您導回登入頁。') }}
      </modal-confirm>
    </div>
  </div>
</template>

<script>
import {Device} from '@capacitor/device';
import fbTrack from '@/lib/base/fbTrack';
import ModalConfirm from '@/components/Modal/ModalConfirm.vue';
import userService from '@/services/user';

export default {
  components: {ModalConfirm},
  data() {
    return {
      isRedirectModalShow: false,
      isValid: false,
      deviceInfo: null,
    };
  },
  computed: {
    device() {
      return this.$store.state.env.device;
    },
    code() {
      return this.$route.query.code;
    },
    id() {
      return this.$route.query.id;
    },
    isLoading() {
      return this.$store.getters['env/isLoading'];
    },
    isPhoneBrowser() {
      return (
        this.deviceInfo &&
        this.deviceInfo.platform === 'web' &&
        this.viewMode !== 'web'
      );
    },
  },
  async created() {
    this.deviceInfo = await Device.getInfo();
  },
  mounted() {
    this.validSignupEmail();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    goAppStore() {
      const url =
        this.device === 'ios'
          ? 'https://apps.apple.com/us/app/%E9%BB%91%E5%98%89%E5%98%89%E5%9C%8D%E6%A3%8B%E6%95%99%E5%AE%A4/id1581621835'
          : 'https://play.google.com/store/apps/details?id=com.heijiajia.tw';
      window.open(url);
    },
    goHome() {
      this.isRedirectModalShow = false;
      this.$router.push({
        name: 'home',
      });
    },
    async validSignupEmail() {
      if (!this.id || !this.code) {
        this.isRedirectModalShow = true;
        return;
      }
      this.$store.commit('env/setIsLoading', true);
      try {
        const data = {
          verificationId: this.id,
          verificationCode: this.code,
          type: 'EMAIL_SET',
        };
        await userService.setUsernameByOtp(data);
        this.$store.commit('env/setIsLoading', false);
        this.isValid = true;
        fbTrack('CompleteBindAccount');
        this.$gtmTrack('gtmCompleteBindAccount');
      } catch (error) {
        this.$store.commit('env/setIsLoading', false);
        this.isRedirectModalShow = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  object-fit: contain;
  max-width: 320px;
  max-height: 320px;
  @media screen and (max-width: 768px) {
    max-width: 220px;
    max-height: 220px;
  }
}
.logo {
  padding: 24px 32px;
  width: 100%;
  max-width: 320px;
  @media screen and (max-width: 768px) {
    padding: 0 16px;
    height: 79px;
    max-width: 220px;
  }
}
.app-title {
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  .btn {
    width: 240px;
  }
  .content {
    color: $font-grayscale-1;
  }
}
</style>
